interface IAuthorization{
  saveToken(token: string): void;
  cleanToken(): void;
  hasToken(): boolean;
  getToken(): string;
}

class Authorization implements IAuthorization{

  private _tokenKey = 'org-token';


  saveToken(token: string): void {
    sessionStorage.setItem(this._tokenKey, token);
  }

  cleanToken(): void {
    sessionStorage.removeItem(this._tokenKey);
  }

  hasToken(): boolean {
    return !!sessionStorage.getItem(this._tokenKey)
  }

  getToken(): string {
    return sessionStorage.getItem(this._tokenKey) ?? '';
  }

}

export const authorization = new Authorization();