import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style.scss";

import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);
app.use(router).mount("#app");

const options: PluginOptions = {
  position: "bottom-right" as POSITION,
  timeout: 7000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: false,
  rtl: false,
};

app.use(Toast, options);
