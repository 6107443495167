import { RouteRecordRaw } from "vue-router";

export const personRoutes: Array<RouteRecordRaw> = [
  {
    path: "/persons",
    name: "person-list",
    component: () => import("#views/person/PersonView.vue"),
    meta: {
      title: "Osoby",
    },
  },
  {
    path: "/persons/:id",
    name: "person-details",
    props: true,
    component: () => import("#views/person/PersonDetailView.vue"),
    meta: {
      title: "Osoba",
    },
  },
  {
    path: "/persons/update/:id",
    name: "person-update",
    props: true,
    component: () => import("#views/person/PersonUpdateView.vue"),
    meta: {
      title: "Edycja osoby",
    },
  },
  {
    path: "/persons/create",
    name: "person-create",
    component: () => import("#views/person/PersonCreateView.vue"),
    meta: {
      title: "Dodaj osobę",
    },
  },
];
