import { RouteRecordRaw } from "vue-router";

export const billRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/bills",
    name: "finance-bill-list",
    component: () => import("#views/finance/bill/BillListView.vue"),
    meta: {
      title: "Rachunki",
    },
  },
  {
    path: "/finance/bills/create",
    name: "finance-bill-create",
    component: () => import("#views/finance/bill/BillCreateView.vue"),
    meta: {
      title: "Dodaj rachunek",
    },
  },
  {
    path: "/finance/bills/update/:id",
    name: "finance-bill-update",
    props: true,
    component: () => import("#views/finance/bill/BillUpdateView.vue"),
    meta: {
      title: "Edytuj rachunek",
    },
  },
  {
    path: "/finance/bills/:id",
    name: "finance-bill-details",
    props: true,
    component: () => import("#views/finance/bill/BillDetailView.vue"),
    meta: {
      title: "Rachunek",
    },
  },
];
