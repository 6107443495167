import { RouteRecordRaw } from "vue-router";

export const subscriptionRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/subscriptions",
    name: "finance-subscription-list",
    component: () =>
      import("#views/finance/subscription/SubscriptionListView.vue"),
    meta: {
      title: "Subskrypcje",
    },
  },
  {
    path: "/finance/subscriptions/create",
    name: "finance-subscription-create",
    component: () =>
      import("#views/finance/subscription/SubscriptionCreateView.vue"),
    meta: {
      title: "Dodaj subskrypcję",
    },
  },
  {
    path: "/finance/subscriptions/update/:id",
    name: "finance-subscription-update",
    props: true,
    component: () =>
      import("#views/finance/subscription/SubscriptionUpdateView.vue"),
    meta: {
      title: "Edytuj subskrypcję",
    },
  },
  {
    path: "/finance/subscriptions/:id",
    name: "finance-subscription-details",
    props: true,
    component: () =>
      import("#views/finance/subscription/SubscriptionDetailView.vue"),
    meta: {
      title: "Subksrypcja",
    },
  },
];
