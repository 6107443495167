import { RouteRecordRaw } from "vue-router";

export const loanRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/loans",
    name: "finance-loan-list",
    component: () => import("#views/finance/loan/LoanListView.vue"),
    meta: {
      title: "Kredyty",
    },
  },
  {
    path: "/finance/loans/create",
    name: "finance-loan-create",
    component: () => import("#views/finance/loan/LoanCreateView.vue"),
    meta: {
      title: "Dodaj kredyt",
    },
  },
  {
    path: "/finance/loans/update/:id",
    name: "finance-loan-update",
    props: true,
    component: () => import("#views/finance/loan/LoanUpdateView.vue"),
    meta: {
      title: "Edytuj kredyt",
    },
  },
  {
    path: "/finance/loans/:id",
    name: "finance-loan-details",
    props: true,
    component: () => import("#views/finance/loan/LoanDetailView.vue"),
    meta: {
      title: "Kredyt",
    },
  },
];
