import { RouteRecordRaw } from "vue-router";

export const bankAccountRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/bank-accounts",
    name: "finance-bank-account-list",
    component: () =>
      import("#views/finance/bank-account/BankAccountListView.vue"),
    meta: {
      title: "Konta bankowe",
    },
  },
  {
    path: "/finance/bank-accounts/create",
    name: "finance-bank-account-create",
    component: () =>
      import("#views/finance/bank-account/BankAccountCreateView.vue"),
    meta: {
      title: "Dodaj konto bankowe",
    },
  },
  {
    path: "/finance/bank-accounts/update/:id",
    name: "finance-bank-account-update",
    props: true,
    component: () =>
      import("#views/finance/bank-account/BankAccountUpdateView.vue"),
    meta: {
      title: "Edytuj konto bankowe",
    },
  },
  {
    path: "/finance/bank-accounts/:id",
    name: "finance-bank-account-details",
    props: true,
    component: () =>
      import("#views/finance/bank-account/BankAccountDetailView.vue"),
    meta: {
      title: "Konto bankowe",
    },
  },
  {
    path: "/finance/bank-accounts/statistics/:id",
    name: "finance-bank-account-statistics",
    props: true,
    component: () =>
      import("#views/finance/bank-account/BankAccountStatisticView.vue"),
    meta: {
      title: "Konto bankowe - statystyki",
    },
  },
];
