import { RouteRecordRaw } from "vue-router";

export const paymentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/expenses/payments",
    name: "finance-expense-payment-list",
    component: () => import("#views/finance/payment/PaymentListView.vue"),
    meta: {
      title: "Wydatki",
    },
  },
  {
    path: "/finance/expenses/payments/create",
    name: "finance-expense-payment-create",
    component: () => import("#views/finance/payment/PaymentCreateView.vue"),
    meta: {
      title: "Dodaj wydatek",
    },
  },
  {
    path: "/finance/expenses/payments/update/:id",
    name: "finance-expense-payment-update",
    props: true,
    component: () => import("#views/finance/payment/PaymentUpdateView.vue"),
    meta: {
      title: "Edytuj wydatek",
    },
  },
  {
    path: "/finance/expenses/payments/:id",
    name: "finance-expense-payment-details",
    props: true,
    component: () => import("#views/finance/payment/PaymentDetailView.vue"),
    meta: {
      title: "Wydatek",
    },
  },
];
