import { RouteRecordRaw } from "vue-router";

export const incomeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/incomes",
    name: "finance-income-list",
    component: () => import("#views/finance/income/IncomeListView.vue"),
    meta: {
      title: "Źródła przychodów",
    },
  },
  {
    path: "/finance/incomes/create",
    name: "finance-income-create",
    component: () => import("#views/finance/income/IncomeCreateView.vue"),
    meta: {
      title: "Dodaj źródło przychodu",
    },
  },
  {
    path: "/finance/incomes/update/:id",
    name: "finance-income-update",
    props: true,
    component: () => import("#views/finance/income/IncomeUpdateView.vue"),
    meta: {
      title: "Edytuj źródło przychodu",
    },
  },
  {
    path: "/finance/incomes/:id",
    name: "finance-income-details",
    props: true,
    component: () => import("#views/finance/income/IncomeDetailView.vue"),
    meta: {
      title: "Źródło przychodu",
    },
  },
];
