import { RouteRecordRaw } from "vue-router";

export const expenseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/expenses",
    name: "finance-expense-list",
    component: () => import("#views/finance/expense/ExpenseListView.vue"),
    meta: {
      title: "Pozostałe wydatki",
    },
  },
  {
    path: "/finance/expenses/create",
    name: "finance-expense-create",
    component: () => import("#views/finance/expense/ExpenseCreateView.vue"),
    meta: {
      title: "Dodaj wydatek stały",
    },
  },
  {
    path: "/finance/expenses/update/:id",
    name: "finance-expense-update",
    props: true,
    component: () => import("#views/finance/expense/ExpenseUpdateView.vue"),
    meta: {
      title: "Edytuj wydatek stały",
    },
  },
  {
    path: "/finance/expenses/:id",
    name: "finance-expense-details",
    props: true,
    component: () => import("#views/finance/expense/ExpenseDetailView.vue"),
    meta: {
      title: "Wydatek stały",
    },
  },
];
