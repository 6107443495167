import { RouteRecordRaw } from "vue-router";

export const incomePaymentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/incomes/payments",
    name: "finance-income-payment-list",
    component: () =>
      import("#views/finance/income-payment/IncomePaymentListView.vue"),
    meta: {
      title: "Wypłaty przychodów",
    },
  },
  {
    path: "/finance/incomes/payments/create",
    name: "finance-income-payment-create",
    component: () =>
      import("#views/finance/income-payment/IncomePaymentCreateView.vue"),
    meta: {
      title: "Dodaj wypłatę",
    },
  },
  {
    path: "/finance/incomes/payments/update/:id",
    name: "finance-income-payment-update",
    props: true,
    component: () =>
      import("#views/finance/income-payment/IncomePaymentUpdateView.vue"),
    meta: {
      title: "Edytuj wypłatę",
    },
  },
  {
    path: "/finance/incomes/payments/:id",
    name: "finance-income-payment-details",
    props: true,
    component: () =>
      import("#views/finance/income-payment/IncomePaymentDetailView.vue"),
    meta: {
      title: "Wypłąta przychodu",
    },
  },
];
