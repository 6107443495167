import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "#views/HomeView.vue";
import { accountRoutes } from "@account/routing/account-rutes";
import { personRoutes } from "@person/routing/person-routes";
import { periodRoutes } from "@finance/period/routes";
import { bankAccountRoutes } from "@finance/bank-account/routes";
import { billRoutes } from "@finance/bill/routes";
import { directDebitRoutes } from "@finance/direct-debit/routes";
import { subscriptionRoutes } from "@finance/subscription/routes";
import { loanRoutes } from "@finance/loan/routes";
import { expenseRoutes } from "@finance/expense/routes";
import { incomeRoutes } from "@finance/income/routes";
import { incomePaymentRoutes } from "@finance/income-payment/routes";
import { paymentRoutes } from "@finance/payment/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("#views/finance/dashboard/DashboardView.vue"),
    meta: {
      title: "Mój Organizer",
    },
  },
  {
    path: "/finanse",
    name: "finance-dashboard",
    component: () => import("#views/finance/dashboard/DashboardView.vue"),
    meta: {
      title: "Finanse - pulpit",
    },
  },
  ...accountRoutes,
  ...personRoutes,
  ...periodRoutes,
  ...bankAccountRoutes,
  ...billRoutes,
  ...directDebitRoutes,
  ...subscriptionRoutes,
  ...loanRoutes,
  ...expenseRoutes,
  ...incomeRoutes,
  ...incomePaymentRoutes,
  ...paymentRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = String(to.meta.title) ?? "Mój Organizer";
  next();
});

export default router;
