import { RouteRecordRaw } from "vue-router";

export const directDebitRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/direct-debits",
    name: "finance-direct-debit-list",
    component: () =>
      import("#views/finance/direct-debit/DirectDebitListView.vue"),
    meta: {
      title: "Zlecenia stałe",
    },
  },
  {
    path: "/finance/direct-debits/create",
    name: "finance-direct-debit-create",
    component: () =>
      import("#views/finance/direct-debit/DirectDebitCreateView.vue"),
    meta: {
      title: "Dodaj zlecenie stałe",
    },
  },
  {
    path: "/finance/direct-debits/update/:id",
    name: "finance-direct-debit-update",
    props: true,
    component: () =>
      import("#views/finance/direct-debit/DirectDebitUpdateView.vue"),
    meta: {
      title: "Edytuj zlecenie stałe",
    },
  },
  {
    path: "/finance/direct-debits/:id",
    name: "finance-direct-debit-details",
    props: true,
    component: () =>
      import("#views/finance/direct-debit/DirectDebitDetailView.vue"),
    meta: {
      title: "Zlecenie stałe",
    },
  },
];
