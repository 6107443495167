import { RouteRecordRaw } from "vue-router";

export const periodRoutes: Array<RouteRecordRaw> = [
  {
    path: "/finance/periods",
    name: "finance-period-list",
    component: () => import("#views/finance/period/PeriodListView.vue"),
    meta: {
      title: "Miesiące płacowe",
    },
  },
  {
    path: "/finance/periods/create",
    name: "finance-period-create",
    component: () => import("#views/finance/period/PeriodCreateView.vue"),
    meta: {
      title: "Doda miesiąc płacowy",
    },
  },
  {
    path: "/finance/periods/update/:id",
    name: "finance-period-update",
    props: true,
    component: () => import("#views/finance/period/PeriodUpdateView.vue"),
    meta: {
      title: "Edytu miesiąc płacowy",
    },
  },
  {
    path: "/finance/periods/:id",
    name: "finance-period-details",
    props: true,
    component: () => import("#views/finance/period/PeriodDetailView.vue"),
    meta: {
      title: "Miesiąc płacowy",
    },
  },
];
