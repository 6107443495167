import { RouteRecordRaw } from "vue-router";

export const accountRoutes: Array<RouteRecordRaw> = [
  {
    path: "/account/login",
    name: "login",
    props: true,
    component: () => import("#views/account/LoginView.vue"),
    meta: {
      title: "Logowanie",
    },
  },
];
